<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="所属代理商" prop="cid">
        <el-select
          style="width: 100%"
          v-model="companyName"
          clearable
          :placeholder="
            !dataForm.id
              ? '不选代理商代表是总部员工，可管理所有代理商数据'
              : companyName
          "
          @change="changeCompany"
          filterable
          @focus="companySelectFocus"
        >
          <el-option
            v-for="item in companyOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色名" prop="name">
        <el-input v-model="dataForm.name" placeholder="角色名"></el-input>
      </el-form-item>

      <el-form-item label="权限" prop="menus">
        <div>
          <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox
              v-for="menu in menuList"
              :label="menu.id"
              :key="menu.id"
              >{{ menu.name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  saveRole,
  updateRole,
  getRoleInfo,
  getCompanyList,
  getMenuList,
} from "../../api/api";
export default {
  data() {
    return {
      checkAll: false,
      isIndeterminate: true,
      checkedCities: [],
      visible: false,
      companyName: "",
      companyOptions: [],
      menuList: [], //菜单列表
      dataForm: {
        id: 0,
        cid: "",
        name: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
      },
      dataRule: {
        name: [{ required: true, message: "角色名不能为空", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.menuList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.menuList.length;
    },
    // 选择代理商的事件
    changeCompany(value) {
      this.dataForm.cid = value;
    },
    // 代理商选择器获得焦点事件
    companySelectFocus() {
      if (this.companyOptions.length > 0) {
        return;
      }
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.type != 0) {
        return;
      }

      this.dataListLoading = true;
      getCompanyList({ limit: 9999 }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.companyOptions = [];
            for (let item of resultList) {
              this.companyOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    init(id) {
      this.dataListLoading = true;
      // 获取菜单列表
      getMenuList({}).then((data) => {
        if (data && data.code === 0) {
          this.menuList = data.data;

          this.checkedCities = [];
          // 初始化数据
          this.dataForm.id = id || 0;
          this.visible = true;
          this.companyName = "";

          this.$nextTick(() => {
            this.$refs["dataForm"].resetFields();
            let userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (userInfo.type == 1) {
              this.dataForm.cid = userInfo.company.id;
              this.companyOptions.push({
                value: this.dataForm.cid,
                label: userInfo.company.name,
              });
              this.companyName = userInfo.company.name;
            }
            if (this.dataForm.id) {
              getRoleInfo({ id: this.dataForm.id }).then((data) => {
                if (data && data.code === 0) {
                  this.dataForm = data.data;
                  if (data.data.companyName) {
                    this.companyName = data.data.companyName;
                  } else {
                    this.companyName =
                      "不选代理商代表是总部员工，可管理所有代理商数据";
                  }

                  for (let menu of this.menuList) {
                    for (let roleMenu of data.data.menus) {
                      if (roleMenu.id == menu.id) {
                        this.checkedCities.push(menu.id);
                      }
                    }
                  }
                }
              });
            }
          });
        } else {
          this.menuList = [];
        }
        this.dataListLoading = false;
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;
          requestDate.menus = this.checkedCities;
          if (this.dataForm.id) {
            // 修改
            updateRole(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            saveRole(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
