<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm">
      <el-form-item v-if="showCompanySelect">
        <el-select
          v-model="dataForm.cid"
          clearable
          :placeholder="companyName ? companyName : '按代理商筛选'"
          filterable
          @focus="companySelectFocus"
          style="margin-right: 10px"
        >
          <el-option
            v-for="item in companyOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()" v-if="showCompanySelect"
          >查询</el-button
        >
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="编号"
      >
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="所属代理商"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="角色名"
      >
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>

      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./role-add-or-update";
import router from "../../router";
import { deleteRoleById, getRoleList, getCompanyList } from "../../api/api";
export default {
  data() {
    return {
      dataForm: {
        cid: "",
      },
      companyName: "",
      // 代理商选择框列表
      companyOptions: [],
      showCompanySelect: false, // 是否显示代理商选择框

      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      router.push("/login");
      return;
    }
    if (userInfo.type == 0) {
      // 显示代理商选择框
      this.showCompanySelect = true;
    } else {
      // 限定cid
      this.dataForm.cid = userInfo.company.id;
    }
    this.getDataList();
  },
  methods: {
    // 代理商选择框获得焦点事件
    companySelectFocus() {
      if (this.companyOptions.length > 0) {
        return;
      }
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.type == 1) {
        return;
      }

      this.dataListLoading = true;
      getCompanyList({ limit: 9999 }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.companyOptions = [];
            for (let item of resultList) {
              this.companyOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;
      getRoleList(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteRoleById({
          id,
        }).then((data) => {
          if (data && data.code === 0) {
            this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
  },
};
</script>
